import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { GET_CURRENCY, GET_EARNING_SCHEME } from '@/store/references/variables'
import { getCurrencies, getEarningScheme } from '@/store/references/api'

export interface IReferenceState {
    loading: boolean
    earningScheme: Array<any>
    currencies: Array<any>
}
const initialState: IReferenceState = {
    loading: false,
    earningScheme: [],
    currencies: [],
}

const getEarningScheme$ = createAsyncThunk(GET_EARNING_SCHEME, async () => {
    const response = await getEarningScheme()
    return Promise.resolve(response.data)
})

const getCurrencies$ = createAsyncThunk(GET_CURRENCY, async () => {
    const response = await getCurrencies()
    return Promise.resolve(response.data)
})

export const referenceSlice = createSlice({
    name: 'reference',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getEarningScheme$.fulfilled, (state, action) => {
            state.earningScheme = action.payload as any
        })
        builder.addCase(getCurrencies$.fulfilled, (state, action) => {
            state.currencies = action.payload as any
            // state.currencies = [...state.currencies, 'LTC', 'BCH']
        })
    },
})

export const referenceReducer = referenceSlice.reducer

export { getCurrencies$, getEarningScheme$ }
