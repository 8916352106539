import { SetStateAction, useContext, useLayoutEffect, useState } from 'react'
import { GlobalContext } from '@/entities'
import { useSubAccount } from '@/entities/cabinet'

export function CoinsComponent({
    showCoins,
}: {
    showCoins: React.Dispatch<SetStateAction<string>>
}) {
    const { setCurrency, currency } = useContext(GlobalContext)
    const { subAccounts, currentSubAccount } = useSubAccount()
    const [coins, setCoins] = useState([
        {
            name: 'ALL COINS',
            type: 'all',
        },
        {
            name: 'BTC',
            type: 'BTC',
        },
        {
            name: 'LTC',
            type: 'LTC',
        },
        {
            name: 'BCH',
            type: 'BCH',
        },
    ])
    useLayoutEffect(() => {
        if (!currentSubAccount) {
            return
        }
        const existedCoins = [{ name: 'ALL COINS', type: 'all' }]
        for (const coin of coins) {
            if (
                subAccounts.find(
                    (account) => account.crypto_currency === coin.type
                )
            ) {
                existedCoins.push(coin)
            }
        }
        setCoins(existedCoins)
    }, [currentSubAccount])
    return (
        <div className="langs-drop" id="langBox">
            <ul className="langs-list">
                {coins.map((value, key) => (
                    <li
                        onClick={(e) => {
                            e.preventDefault()
                            setCurrency(value.type)
                            showCoins(value.type)
                            window.sessionStorage.setItem(
                                'currency',
                                value.type
                            )
                        }}
                        key={key}
                        className={currency === value.type ? 'active' : ''}
                    >
                        <a href="#" className="js_changeLang">
                            <span>{value.name}</span>
                        </a>
                    </li>
                ))}
            </ul>
        </div>
    )
}
