import { CButton, Dialog } from '@/shared/ui'
import style from './index.module.scss'
import { CInput } from '@/shared/ui/cabinet'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { setDialogs } from '@/store/sub_accounts'
import { useAppDispatch } from '@/store'
import { EDIT_EMAIL } from '@/entities/cabinet'

const WalletScheme = Yup.object().shape({
    email: Yup.string().required('Required'),
})

export function EditEmailAlert({
    email,
    saveEmail,
}: {
    email: string
    saveEmail: (val: string) => void
}) {
    const dispatch = useAppDispatch()
    const formik = useFormik({
        initialValues: { email: '' },
        validationSchema: WalletScheme,
        validateOnChange: false,
        onSubmit: (values, formikHelpers) => {
            saveEmail(values.email)
        },
    })
    return (
        <Dialog
            title={'Edit Email Address'}
            onClose={() =>
                dispatch(setDialogs({ key: EDIT_EMAIL, value: false }))
            }
            body={
                <div className={style.editWallet}>
                    <form
                        className={style.editWallet__form}
                        onSubmit={(e) => formik.handleSubmit(e)}
                    >
                        <div className={style.editWallet__form__prev}>
                            <span>Current Email</span>
                            <p>{email}</p>
                        </div>
                        <CInput
                            error={formik.errors.email}
                            name={'email'}
                            onChange={formik.handleChange}
                            label={'New Email Address'}
                            placeholder={'Email Address'}
                        />
                        <div className={style.editWallet__form__warning}>
                            * Changing your email will require you to verify
                            your e-mail before you can log in again.
                        </div>
                        <div className={style.editWallet__form__btn}>
                            <CButton
                                value={'Edit'}
                                type={'submit'}
                                variant={'general'}
                            />
                        </div>
                    </form>
                </div>
            }
        />
    )
}
