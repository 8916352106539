import axiosClient from '@/shared/libs/axiosClient'

export const enum REFERENCE_URL {
    EARNING_SCHEME = '/api/v1/sub_accounts/references/earning_schemes/',
    CURRENCY = '/api/v1/sub_accounts/references/crypto_currency/',
}

export const getEarningScheme = () => {
    return axiosClient.get(REFERENCE_URL.EARNING_SCHEME)
}

export const getCurrencies = () => {
    return axiosClient.get(REFERENCE_URL.CURRENCY)
}
