import { createContext, ReactNode } from 'react'
import { useDialog } from '@/entities'

export const DialogContext = createContext<{
    items: { [key in string]: boolean }
    setItems: React.Dispatch<any>
}>({
    items: {},
    setItems: (val) => {},
})

export function DialogProvider({ children }: { children: ReactNode }) {
    const props = useDialog()
    return (
        <DialogContext.Provider value={{ ...props }}>
            {children}
        </DialogContext.Provider>
    )
}
