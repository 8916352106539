import { useEffect, useState } from 'react'
import { useTheme } from '@/features'

export function useChangingTheme() {
    const { currentTheme, switchTheme } = useTheme()

    const [isDark, setIsDark] = useState(currentTheme === 'dark')

    useEffect(() => {
        switchTheme(isDark ? 'dark' : 'light')
        if (isDark) {
            document.body.classList.add('dark')
        } else {
            document.body.classList.remove('dark')
        }
    }, [isDark])
    return {
        currentTheme,
        isDark,
        setIsDark,
    }
}

export function DarkModeToggle() {
    const { isDark, setIsDark } = useChangingTheme()
    return (
        <button onClick={() => setIsDark(!isDark)}>
            <img
                src={
                    require(
                        !isDark
                            ? `@/shared/images/svg/header-theme-switch-dark.svg`
                            : '@/shared/images/svg/header-theme-switch-light.svg'
                    ).default
                }
                alt="theme icon"
            />
        </button>
    )
}
