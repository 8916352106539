import ruMessage from '@/shared/messages/ru.json'
import esMessage from '@/shared/messages/es.json'
import ptMessage from '@/shared/messages/pt.json'
import enMessage from '@/shared/messages/en.json'
import arMessage from '@/shared/messages/ar.json'

export const messages: { [key in any]: any } = {
    ru: ruMessage,
    en: enMessage,
    es: esMessage,
    pt: ptMessage,
    ar: arMessage,
}
