import HighchartsReact from 'highcharts-react-official'
import * as Highcharts from 'highcharts'
import { useRef } from 'react'

function chartRandomValuesGenerator(
    amountValues: any,
    startValue: any,
    volantilnostPercent: any,
    maxWaveStepDown: any,
    minValue: any
) {
    let lastValue = startValue
    let arrayOfRandomNumbers = []
    for (let i = 0; i < amountValues; i++) {
        let currentPercent = (lastValue / 100) * volantilnostPercent
        if (Math.random() < 0.5) {
            lastValue -= currentPercent
        } else {
            lastValue += currentPercent
        }
        if (lastValue <= minValue) {
            lastValue += minValue
        }
        arrayOfRandomNumbers.push(parseInt(lastValue))
    }
    return arrayOfRandomNumbers
}

const options = {
    chart: {
        backgroundColor: false,
        type: 'spline',
        margin: 0,
        height: 60,
        marginTop: 0,
        borderWidth: 0,
        spacingLeft: 0,
        spacingRight: 0,
    },
    exporting: { enabled: false },
    title: false,
    subtitle: false,
    legend: {
        enabled: false,
    },
    credits: {
        enabled: false,
    },
    xAxis: {
        type: 'datetime',
        visible: false,
        labels: {
            enabled: false,
        },
    },
    tooltip: {
        formatter: function () {
            return '<b>' + Highcharts.numberFormat(1, 1, '.', ' ') + ' </b>'
        } as any,
    },

    plotOptions: {
        series: {
            shadow: {
                offsetY: 13,
                width: 5,
                color: '#eee',
            },
            marker: {
                enabled: false,
            },
            animation: {
                duration: 2000,
            },
        },
        spline: {
            marker: {
                enabled: false,
            },
        },
    },
    series: [
        {
            name: 'Value ',
            color: '#01BD97',
            lineWidth: 1.5,
            data: chartRandomValuesGenerator(10, 40000, 20, 5, 30000),
        },
    ],
}

export function CoinComponent() {
    const chartComponentRef = useRef<HighchartsReact.RefObject>(null)

    return (
        <div className="coin-item">
            <div className="item-box">
                <div className="top-info">
                    <div className="icon">
                        <img
                            src={
                                require('@/shared/images/coins/coin-litecoin.svg')
                                    .default
                            }
                            alt=""
                        />
                    </div>
                    <span className="coin-name">Litecoin</span>
                    <span className="encrypt-type">Scrypt</span>
                    <div className="coin-price-box">
                        <span className="price">$37 904,90</span>
                        <span className="percent-diff up">(+0.5%)</span>
                    </div>
                </div>
                <div className="graphic jsGraphicRates up">
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={options}
                        ref={chartComponentRef}
                    />
                </div>
                <div className="bottom-info">
                    <div className="pool-perfomance-box">
                        <span className="perf-value">5.64TH</span>
                        <span className="perf-label">Хешрейт пула</span>
                    </div>
                    <div className="coin-bottom-row">
                        <div className="miners-col">
                            <span className="miners-count">1 803</span>
                            <span className="miners-label">Майнеров</span>
                        </div>
                        <div className="go-coin-col">
                            <a href="coin.html" className="coin-arrow-go-btn">
                                <img
                                    src={
                                        require('@/shared/images/svg/arrow-slider-blue.svg')
                                            .default
                                    }
                                    alt=""
                                />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
