import React from 'react'
import style from './index.module.scss'

interface IProps extends React.InputHTMLAttributes<any> {
    options?: { key: string; value: string }[]
    error: string | undefined
    label?: string
}

export function Select({ options, error, label, ...props }: IProps) {
    return (
        <div className={style.wrapper}>
            {label && <label>{label}</label>}
            <select {...props} className={style.select}>
                {options?.map((option, idx) => (
                    <option value={option.key} key={idx}>
                        {option.value}
                    </option>
                ))}
            </select>
            {error && <span className={style.error}>{error}</span>}
        </div>
    )
}
