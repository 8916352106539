import { customRequest } from '@/shared/utils'
import {
    IPersonalDetail,
    IPersonalDetailBody,
    IVerificationSendBody,
    IVerificationVerifyBody,
} from '@/entities/cabinet'

const enum API_URL {
    userDetails = '/api/v1/users/profile/details/',
    changeDetails = '/api/v1/users/profile/details/update/',
    verificationSend = '/api/v1/users/verification_code/send/',
    verifyCode = '/api/v1/users/verification_code/verify/',
}
export function getUserDetailApi() {
    return customRequest
        .getItem<IPersonalDetail, any>(API_URL.userDetails)
        .then((res) => res.data)
}

export function updateUserDetailsApi(form: IPersonalDetailBody) {
    return customRequest.patch(API_URL.changeDetails, form)
}

export function verificationSend(form: IVerificationSendBody) {
    return customRequest.post(API_URL.verificationSend, form)
}

export function verificationVerify(form: IVerificationVerifyBody) {
    return customRequest
        .post(API_URL.verifyCode, form)
        .then((res: any) => res.data)
}
