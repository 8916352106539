import style from './index.module.scss'
import classNames from 'classnames'

interface IProps {
    title: string
    description: string
    color?: 'error' | 'warning' | 'success'
}

export function Alert({ title, description, color }: IProps) {
    return (
        <div className={classNames(style.popup, color ? style[color] : '')}>
            <div className={'icon'}>
                {color !== 'success' ? (
                    <img
                        src={require('@/shared/images/error-alert.png')}
                        alt={'error-alert-image'}
                        width={20}
                        height={20}
                    />
                ) : (
                    ''
                )}
            </div>
            <div className={'popup__content'}>
                {title && (
                    <div className={style.popup__content__title}>{title}</div>
                )}
                {description && (
                    <div className={style.popup__content__description}>
                        {description}
                    </div>
                )}
            </div>
        </div>
    )
}
