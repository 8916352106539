import { LanguageComponent } from '@/features'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useContext, useEffect, useState } from 'react'
import { GlobalContext, useAuth } from '@/entities'
import classNames from 'classnames'
import { useTranslations } from 'use-intl'
import style from './header.module.scss'
import Logo from '@/shared/ui/Logo'
import { useAppDispatch } from '@/store'
import { setCurrentSubAccount, setDialogs } from '@/store/sub_accounts'
import { LOGOUT_DIALOG } from '@/entities/cabinet'
const LangSelector = ({
    lang,
    setShowLangs,
    showLangs,
}: {
    lang: string
    setShowLangs: any
    showLangs: boolean
}) => {
    return (
        <div className={style.header__right__btns__lang}>
            <button
                type="button"
                className="btn"
                onClick={() => setShowLangs((val: any) => !val)}
            >
                <span>{lang}</span>
                <span>
                    <img
                        src={
                            require('@/shared/images/svg/bottom-arrow.svg')
                                .default
                        }
                        alt="bottomArrow"
                    />
                </span>
            </button>
            {showLangs && <LanguageComponent setShowLangs={setShowLangs} />}
        </div>
    )
}

export function Header() {
    const [showLangs, setShowLangs] = useState<boolean>(false)
    const navigate = useNavigate()
    const location = useLocation()
    const { openMenu, isLogged, currentUser, sidebarState, lang } =
        useContext(GlobalContext)
    const [isOpenedMenu, setIsOpen] = openMenu
    const [isSidebarShow, showSidebar] = sidebarState
    const { pathname } = useLocation()
    const { logout } = useAuth()
    const dispatch = useAppDispatch()
    const { setCurrentUser, setIsLogged } = useContext(GlobalContext)
    const i18n = useTranslations('header')

    return (
        <header
            className="site-header"
            style={{ position: 'sticky', top: 0, zIndex: 100 }}
        >
            <div className={classNames('header-top', style.headerTop)}>
                <div className={style.headerTop__container}>
                    <div className={style.header}>
                        <div className={style.header__left}>
                            <div className={style.header__left__logo}>
                                <Link to="/" className="logo">
                                    <Logo />
                                </Link>
                            </div>
                        </div>
                        <div className={style.header__center}>
                            <div className={style.header__left__links}>
                                {location.pathname === '/' ? (
                                    <>
                                        <a href="#about">
                                            {i18n('links.about')}
                                        </a>
                                        <a href="#calculator">
                                            {i18n('links.calculator')}
                                        </a>
                                    </>
                                ) : (
                                    <>
                                        <Link to="/#about">
                                            {i18n('links.about')}
                                        </Link>
                                        <Link to="/#calculator">
                                            {i18n('links.calculator')}
                                        </Link>
                                    </>
                                )}
                                <Link to="/faq">{i18n('links.faq')}</Link>
                                {/*<a href="/statistic">Statistic</a>*/}
                            </div>
                        </div>
                        <div className={style.header__right}>
                            <div className={style.header__right__btns}>
                                <LangSelector
                                    lang={lang}
                                    setShowLangs={setShowLangs}
                                    showLangs={showLangs}
                                />
                                <div
                                    className={style.header__right__btns__links}
                                >
                                    {!isLogged && (
                                        <>
                                            <button
                                                onClick={() =>
                                                    navigate('/auth/login')
                                                }
                                            >
                                                {i18n('btns.login')}
                                            </button>
                                            <button
                                                className={style.active}
                                                onClick={() =>
                                                    navigate('/auth/signup')
                                                }
                                            >
                                                {i18n('btns.signup')}
                                            </button>
                                        </>
                                    )}
                                </div>
                            </div>
                            {currentUser && (
                                <>
                                    <div
                                        className={classNames(
                                            'logined-user-box',
                                            isSidebarShow ? 'btn-actived' : ''
                                        )}
                                        onClick={() => {
                                            if (!pathname.includes('cabinet')) {
                                                navigate('/cabinet')
                                            } else {
                                                showSidebar(
                                                    (prev: boolean) => !prev
                                                )
                                            }
                                        }}
                                    >
                                        <button
                                            type="button"
                                            className="abs-link jsXsOpenSidebarBtn"
                                        />
                                        <div className="ava no-ava"></div>
                                        <div className="user-desc">
                                            <span className="user-label">
                                                {i18n('rightColUser.welcome')}
                                            </span>
                                            <div className="user-name">
                                                <span>
                                                    {currentUser?.user.username}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <img
                                        src={
                                            require('@/shared/images/svg/logout.svg')
                                                .default
                                        }
                                        style={{
                                            marginLeft: '10px',
                                            cursor: 'pointer',
                                        }}
                                        alt="logout"
                                        onClick={() => {
                                            logout().then(() => {
                                                setCurrentUser(null)
                                                setIsLogged(false)
                                                dispatch(
                                                    setDialogs({
                                                        key: LOGOUT_DIALOG,
                                                        value: false,
                                                    })
                                                )
                                                dispatch(
                                                    setCurrentSubAccount('')
                                                )
                                                navigate('/')
                                            })
                                        }}
                                    />
                                </>
                            )}
                            {!currentUser && (
                                <button
                                    className={classNames(
                                        'mobile-menu-btn',
                                        isOpenedMenu ? 'btn-actived' : ''
                                    )}
                                    type="button"
                                    onClick={() => {
                                        setIsOpen((val: boolean) => !val)
                                    }}
                                >
                                    <span></span>
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </header>
    )
}
