import { useState } from 'react'

export function useDialog() {
    const [items, setItems] = useState<{ [key in string]: boolean }>({})

    return {
        items,
        setItems,
    }
}
