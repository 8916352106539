import React, { useContext, useEffect, useRef, useState } from 'react'
import style from './index.module.scss'
import { GlobalContext } from '@/entities'

type Option = {
    id: number
    name_official: string
}

type Props = {
    placeHolder?: string
    searchPlaceholder?: string
    notFoundMessage?: string
    options: Option[]
    isMulti?: boolean
    isSearchable?: boolean
    onChange?: (value: Option | Option[]) => void
    align?: 'auto' | 'left' | 'right'
    name?: string
}

type InputEvent = React.ChangeEvent<HTMLInputElement>

const Icon: React.FC<{ isOpen: boolean }> = ({ isOpen }) => {
    return (
        <svg
            viewBox="0 0 24 24"
            width="18"
            height="18"
            stroke="#222"
            strokeWidth="1.5"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            className={`${isOpen && style['translate']}`}
        >
            <polyline points="6 9 12 15 18 9"></polyline>
        </svg>
    )
}

const CloseIcon: React.FC = () => {
    return (
        <svg
            viewBox="0 0 24 24"
            width="14"
            height="14"
            stroke="#fff"
            strokeWidth="2"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <line x1="18" y1="6" x2="6" y2="18"></line>
            <line x1="6" y1="6" x2="18" y2="18"></line>
        </svg>
    )
}

const CountrySelect: React.FC<Props> = ({
    placeHolder = '',
    searchPlaceholder = '',
    notFoundMessage = 'Nothing not found',
    options,
    isMulti = false,
    isSearchable = false,
    onChange,
    align = '',
    name = 'country',
}) => {
    const { lang } = useContext(GlobalContext)
    const [showMenu, setShowMenu] = useState(false)
    const [selectedValue, setSelectedValue] = useState<Option[] | null>(
        isMulti ? [] : null
    )
    const [searchValue, setSearchValue] = useState('')
    const searchRef = useRef<HTMLInputElement | null>(null)
    const inputRef = useRef<HTMLDivElement | null>(null)

    useEffect(() => {
        setSearchValue('')
        if (showMenu && searchRef.current) {
            searchRef.current.focus()
        }
    }, [showMenu])

    useEffect(() => {
        const handler = (e: MouseEvent): void => {
            if (
                inputRef.current &&
                !inputRef.current.contains(e.target as Node) &&
                !searchRef.current?.contains(e.target as Node)
            ) {
                setShowMenu(false)
            }
        }

        window.addEventListener('click', handler)
        return () => {
            window.removeEventListener('click', handler)
        }
    })

    const handleInputClick = (): void => {
        setShowMenu(!showMenu)
    }

    const getDisplay = () => {
        if (!selectedValue) {
            return placeHolder
        }
        if (Array.isArray(selectedValue)) {
            return (
                <div className={`${style['dropdown-tags']}`}>
                    {(selectedValue as Option[]).map((option, index) => (
                        <div
                            key={`${option.id}-${index}`}
                            className={`${style['dropdown-tag-item']}`}
                        >
                            {option.name_official}
                            <span
                                onClick={(e) => onTagRemove(e, option)}
                                className={`${style['dropdown-tag-close']}`}
                            >
                                <CloseIcon />
                            </span>
                        </div>
                    ))}
                </div>
            )
        }

        return (selectedValue as Option).name_official
    }

    const removeOption = (option: Option) => {
        return Array.isArray(selectedValue)
            ? (selectedValue as Option[]).filter((o) => o.id !== option.id)
            : null
    }

    const onTagRemove = (
        e: React.MouseEvent<HTMLSpanElement>,
        option: Option
    ) => {
        e.stopPropagation()
        const newValue = removeOption(option)
        if (newValue !== null) {
            setSelectedValue(newValue)
            onChange && onChange(newValue)
        }
    }

    const onItemClick = (option: Option) => {
        let newValue
        if (Array.isArray(selectedValue)) {
            if (
                (selectedValue as Option[]).findIndex(
                    (o) => o.id === option.id
                ) >= 0
            ) {
                newValue = removeOption(option)
            } else {
                newValue = [...(selectedValue as Option[]), option]
            }
        } else {
            newValue = option
        }
        if (newValue !== null) {
            setSelectedValue(newValue as Option[])
            onChange && onChange(newValue as Option[])
        }
    }

    const isSelected = (option: Option): boolean => {
        if (Array.isArray(selectedValue)) {
            return (
                (selectedValue as Option[]).filter((o) => o.id === option.id)
                    .length > 0
            )
        }

        if (!selectedValue) {
            return false
        }

        return (selectedValue as Option).id === option.id
    }

    const onSearch = (e: InputEvent) => {
        setSearchValue(e.target.value)
    }

    const getOptions = () => {
        if (!searchValue) {
            return options
        }

        return options.filter(
            (option) =>
                option.name_official
                    .toLowerCase()
                    .indexOf(searchValue.toLowerCase()) >= 0
        )
    }

    return (
        <div className={style['custom--dropdown-container']}>
            <div
                ref={inputRef}
                onClick={handleInputClick}
                className={style['dropdown-input']}
            >
                <div
                    className={`${style['dropdown-selected-value']} ${
                        !selectedValue ||
                        (isMulti && !(selectedValue as Option[]).length)
                            ? style['placeholder']
                            : ''
                    }`}
                >
                    {getDisplay()}
                </div>
                <div className={style['dropdown-tools']}>
                    <div className={style['dropdown-tool']}>
                        <Icon isOpen={showMenu} />
                    </div>
                </div>
            </div>

            {showMenu && (
                <div
                    className={`${style['dropdown-menu']} ${
                        style[`alignment--${align || 'auto'}`]
                    }`}
                >
                    {isSearchable && (
                        <div className={style['search-box']}>
                            <input
                                name={name}
                                className={style['form-control']}
                                onChange={onSearch}
                                value={searchValue}
                                ref={searchRef}
                                placeholder={searchPlaceholder}
                            />
                        </div>
                    )}
                    {!!getOptions().length ? (
                        getOptions().map((option) => (
                            <div
                                onClick={() => onItemClick(option)}
                                key={option.id}
                                className={`${style['dropdown-item']} ${
                                    isSelected(option) && style['selected']
                                }`}
                            >
                                <h6>{option.name_official}</h6>
                            </div>
                        ))
                    ) : (
                        <div
                            className={`${style['dropdown-item']} ${style['dropdown-item-not-found']}`}
                        >
                            {notFoundMessage}
                        </div>
                    )}
                </div>
            )}
        </div>
    )
}

export default CountrySelect
