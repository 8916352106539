import { Dialog } from '@/shared/ui'
import style from './index.module.scss'
import { useAppDispatch } from '@/store'
import { setCurrentSubAccount, setDialogs } from '@/store/sub_accounts'
import { LOGOUT_DIALOG } from '@/entities/cabinet'
import { useNavigate } from 'react-router-dom'
import { useContext } from 'react'
import { GlobalContext, useAuth } from '@/entities'
import { useTranslations } from 'use-intl'

export function LogoutDialog() {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const { setCurrentUser, setIsLogged, setCurrency } =
        useContext(GlobalContext)
    const { logout } = useAuth()
    const i18n = useTranslations('cabinet.logout')
    return (
        <Dialog
            title={i18n('logout')}
            onClose={() =>
                dispatch(setDialogs({ key: LOGOUT_DIALOG, value: false }))
            }
            body={
                <div className={style.logout}>
                    <p
                        className={style.logout__subtitle}
                        dangerouslySetInnerHTML={{ __html: i18n('title') }}
                    ></p>
                    <div className={style.logout__btns}>
                        <button
                            onClick={() =>
                                dispatch(
                                    setDialogs({
                                        key: LOGOUT_DIALOG,
                                        value: false,
                                    })
                                )
                            }
                        >
                            {i18n('cancel')}
                        </button>
                        <button
                            onClick={() => {
                                logout().then(() => {
                                    setCurrentUser(null)
                                    setIsLogged(false)
                                    dispatch(
                                        setDialogs({
                                            key: LOGOUT_DIALOG,
                                            value: false,
                                        })
                                    )
                                    dispatch(setCurrentSubAccount(''))
                                    window.sessionStorage.removeItem('currency')
                                    setCurrency('all')
                                    navigate('/')
                                })
                            }}
                        >
                            {i18n('logout')}
                        </button>
                    </div>
                </div>
            }
        />
    )
}
