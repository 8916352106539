import React, { useContext } from 'react'
import { useTranslations } from 'use-intl'
import { GlobalContext } from '@/entities'
import Select from 'react-select'
import { Link } from 'react-router-dom'
import style from '@/widgets/header/header.module.scss'

const LANG_KEY_VALUE: any = {
    ru: 'Русский',
    en: 'English',
}

export function HeaderMobileMenu() {
    const i18n = useTranslations('main.mobileMenu')
    const { changeLanguage, openMenu, lang, currentUser } =
        useContext(GlobalContext)
    const [isOpenedMenu, setIsOpen] = openMenu

    const menuItems = [
        {
            title: i18n('links.about'),
            path: '/#about',
            hashPath: '#about',
        },
        {
            title: i18n('links.calculator'),
            path: '/#calculator',
            hashPath: '#calculator',
        },

        {
            title: i18n('links.faq'),
            path: '/faq',
        },
    ]

    return (
        <div className="mobile-header-inside js_xsNoChangeVH">
            <div className="inside-holder">
                {/* {!currentUser && (*/}
                <div className="head-top">
                    <ul>
                        {menuItems.map(({ title, path, hashPath }) => (
                            <li key={title} onClick={() => setIsOpen(false)}>
                                {window.location.pathname === '/' &&
                                hashPath ? (
                                    <a href={hashPath}>{title}</a>
                                ) : (
                                    <Link
                                        onClick={() => setIsOpen(false)}
                                        to={path}
                                    >
                                        {title}
                                    </Link>
                                )}
                            </li>
                        ))}
                    </ul>
                </div>
                {/*)}*/}
                <div className="head-bottom">
                    <div className="nav-group">
                        <Link
                            onClick={() => setIsOpen(false)}
                            className="mobile__menu__auth__button"
                            to="/auth/login"
                        >
                            {i18n('signIn')}
                        </Link>
                        <Link
                            onClick={() => setIsOpen(false)}
                            className="mobile__menu__auth__button _active"
                            to="/auth/signup"
                        >
                            {i18n('createAccount')}
                        </Link>
                        {/*<span className="nav-label">{i18n('language')}</span>*/}
                        {/*<div className="select-wrapper _gCustomSelect jsSelectCustom">*/}
                        {/*    <Select*/}
                        {/*        className="custom-select "*/}
                        {/*        value={{*/}
                        {/*            value: lang,*/}
                        {/*            label: LANG_KEY_VALUE[lang],*/}
                        {/*        }}*/}
                        {/*        options={[*/}
                        {/*            { label: 'Русский', value: 'ru' },*/}
                        {/*            { label: 'English', value: 'en' },*/}
                        {/*        ]}*/}
                        {/*        onChange={(e: any) => {*/}
                        {/*            changeLanguage(e.value as any)*/}
                        {/*        }}*/}
                        {/*    ></Select>*/}
                        {/*</div>*/}
                    </div>
                </div>
            </div>
        </div>
    )
}
