import Chart from 'react-apexcharts'

interface IProps {
    options: any
    series: any
    type?: any
    height?: any
}

export function CustomChart({ options, series }: IProps) {
    return (
        <>
            <div id="chart">
                <div id="chart-timeline">
                    <Chart
                        options={options}
                        series={series}
                        type="area"
                        height={350}
                    />
                </div>
            </div>
            <div id="html-dist"></div>
        </>
    )
}
