import { SetStateAction, useContext, useLayoutEffect, useState } from 'react'
import { GlobalContext } from '@/entities'
import { useLocation } from 'react-router-dom'

export const ELANG: { [key in string]: string } = {
    en: 'EN',
    ru: 'RU',
    es: 'ES',
    pt: 'PT',
    ar: 'AR',
}

export const LANGS: { name: string; icon: string; code: keyof typeof ELANG }[] =
    [
        { name: 'English', icon: 'fi fi-us', code: 'en' },
        { name: 'Русский', icon: 'fi fi-ru', code: 'ru' },
        { name: 'Español', icon: 'fi fi-es', code: 'es' },
        { name: 'Portuguesa', icon: 'fi fi-pt', code: 'pt' },
        { name: 'عربي', icon: 'fi fi-ar', code: 'ar' },
    ]

export function LanguageComponent({
    setShowLangs,
}: {
    setShowLangs: React.Dispatch<SetStateAction<boolean>>
}) {
    const { changeLanguage, lang } = useContext(GlobalContext)
    const { pathname } = useLocation()
    const [langs, setLangs] = useState(LANGS)
    useLayoutEffect(() => {
        // if (
        //     pathname.includes('cabinet') &&
        //     !langs.find((lang) => lang.code === 'es')
        // ) {
        //     langs.push({ name: 'Español', icon: 'fi fi-es', code: 'es' })
        setLangs([...langs])
        // } else if (!pathname.includes('cabinet')) {
        //     setLangs((langs) => langs.filter((lang) => lang.code !== 'es'))
        // }
    }, [pathname])
    return (
        <div className="langs-drop" id="langBox">
            <ul className="langs-list">
                {langs.map((value, key) => (
                    <li
                        onClick={(e) => {
                            e.preventDefault()
                            changeLanguage(value.code)
                            setShowLangs(false)
                        }}
                        key={key}
                        className={lang === value.code ? 'active' : ''}
                    >
                        <a href="#" className="js_changeLang">
                            <span className={value.icon}></span>
                            <span>{value.name}</span>
                        </a>
                    </li>
                ))}
            </ul>
        </div>
    )
}
