export function toTextDate(date: Date) {
    const strArray = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
    ]
    const month = strArray[date.getMonth()]
    return `${month} ${date.getDate()}, ${date.getFullYear()}`
}
