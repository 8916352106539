import { IntlProvider } from 'use-intl'
import { Outlet } from 'react-router-dom'
import React, { useContext } from 'react'
import { GlobalContext } from '@/entities'
import { messages } from '@/shared/libs'
import { DialogProvider } from '@/entities/global/context/dialog-context'
import { HeaderMobileMenu } from '@/widgets'

export default function WrapperLayout() {
    const { lang, openMenu } = useContext(GlobalContext)
    const [isOpened] = openMenu
    return (
        <IntlProvider messages={messages[lang]} locale={lang}>
            <DialogProvider>
                <Outlet></Outlet>
            </DialogProvider>
            {isOpened && <HeaderMobileMenu />}
        </IntlProvider>
    )
}
