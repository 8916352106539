import React, { StrictMode } from 'react'
import ReactDOM from 'react-dom/client'
import './styles/index.scss'
import App from './app'
import reportWebVitals from './reportWebVitals'
import { Provider } from 'react-redux'
import { store } from '@/store'
import 'react-toastify/dist/ReactToastify.css'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
    <Provider store={store}>
        <App />
    </Provider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
