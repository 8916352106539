import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from '@/store'
import { ITheme } from '@/entities'
import { changeTheme, THEME } from '@/store/global'

export const useTheme = () => {
    const currentTheme = useSelector((state: RootState) => state.global.theme)
    const dispatch = useAppDispatch()
    const switchTheme = (theme: ITheme) => {
        localStorage.setItem(THEME, theme)
        dispatch(changeTheme(theme))
    }
    return { switchTheme, currentTheme }
}
