import { Card, Icon } from '@/shared/ui'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from '@/store'
import { useEffect } from 'react'
import { getSubAccountStratum$ } from '@/store/sub_accounts'
import { useSubAccount } from '@/entities/cabinet'
import style from './index.module.scss'
import { toast } from 'react-toastify'
import { useTranslations } from 'use-intl'

export function CabinetStatrumUrlsComponent() {
    const statrumUrls = useSelector(
        (state: RootState) => state.subAccount.stratumUrls
    )
    const dispatch = useAppDispatch()
    const { currentSubAccount } = useSubAccount()

    useEffect(() => {
        if (currentSubAccount) {
            dispatch(getSubAccountStratum$(currentSubAccount)).unwrap()
        }
    }, [currentSubAccount])
    const i18n = useTranslations('cabinet.home.mining')
    return (
        <div>
            <div className={style.mining}>
                <Card>
                    <h3 className={style.mining__heading}>{i18n('title')}</h3>
                    <p className={style.mining__subtitle}>
                        {i18n('description')}
                    </p>
                    <div className={style.mining__urls}>
                        {statrumUrls.map((obj, key) => (
                            <div key={key}>
                                <div>{obj.description}</div>
                                <div>
                                    <span>{obj.url}</span>
                                    <span
                                        onClick={() => {
                                            toast.success(i18n('Copied'))
                                            navigator.clipboard
                                                .writeText(obj.url)
                                                .then(
                                                    function () {},
                                                    function (err) {}
                                                )
                                        }}
                                    >
                                        <Icon type={'copy'} />
                                    </span>
                                </div>
                            </div>
                        ))}
                        <div>
                            <div>{i18n('worker')}</div>
                            <div>
                                <span>{currentSubAccount}</span>
                                <span
                                    onClick={() => {
                                        toast.success(i18n('Copied'))
                                        navigator.clipboard
                                            .writeText(currentSubAccount)
                                            .then(
                                                function () {},
                                                function () {}
                                            )
                                    }}
                                >
                                    <Icon type={'copy'} />
                                </span>
                            </div>
                        </div>
                        <div>
                            <div>{i18n('Password')}</div>
                            <div>
                                <span>123</span>
                                <span
                                    onClick={() => {
                                        toast.success(i18n('Copied'))
                                        navigator.clipboard
                                            .writeText('123')
                                            .then(
                                                function () {},
                                                function () {}
                                            )
                                    }}
                                >
                                    <Icon type={'copy'} />
                                </span>
                            </div>
                        </div>
                    </div>
                </Card>
            </div>
        </div>
    )
}
