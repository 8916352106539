import React, { useState } from 'react'
import style from './index.module.scss'
import classNames from 'classnames'

interface Props {
    options: any[]
    label?: string
    onChange: (e: any) => void
    value?: string
    customList?: any
    searchBlock?: any
}

export function CDropdown(props: Props) {
    const [opened, setOpened] = useState(false)
    return (
        <div className={style.dropdown}>
            <div
                className={classNames(
                    style.dropdown__btn,
                    opened ? style.active : ''
                )}
                onClick={() => {
                    setOpened((val: boolean) => !val)
                }}
            >
                <div className={style.dropdown__btn__content}>
                    <span>{props.label || 'Subaccounts'}</span>
                    <span>{props.value || ''}</span>
                </div>
                {props.options.length ? (
                    <div className={style.dropdown__btn__image}>
                        <img
                            src={
                                require('@/shared/images/svg/bottom-arrow-white.svg')
                                    .default
                            }
                            alt={'arrow down'}
                        />
                    </div>
                ) : (
                    ''
                )}
            </div>
            {opened && (
                <div className={style.dropdown__content}>
                    {props.searchBlock && (
                        <div className={style.dropdown__content__heading}>
                            {props.searchBlock}
                        </div>
                    )}
                    <div className={style.dropdown__content__inner}>
                        {props.customList}
                        {props.options.map((val, key) => (
                            <div
                                key={key}
                                onClick={() => {
                                    props.onChange(val.key)
                                    setOpened(false)
                                }}
                            >
                                {val.label}
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    )
}
export * from './custom.dropdown'
