import React from 'react'
import style from './index.module.scss'
import classNames from 'classnames'

interface Props extends React.HTMLAttributes<any> {
    children: any
}

export function Card({ children, className, ...props }: Props) {
    return (
        <div className={classNames(style.card, className || '')} {...props}>
            {children}
        </div>
    )
}
