import style from './index.module.scss'
import { IEarningRewardDto } from '@/entities/cabinet'

export function CabinetExpandedRewardComponent({
    items,
    currency,
}: {
    items: IEarningRewardDto[]
    currency: string
}) {
    return (
        <tr>
            <td colSpan={4}>
                <div className={style.expandedContent}>
                    <div className={style.expandedContent__title}>
                        Reward split
                    </div>
                    <table className={style.expandedContent__table}>
                        <thead>
                            <tr>
                                <th>Split</th>
                                <th>Account name</th>
                                <th>Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            {items.map((item, key) => (
                                <tr key={key}>
                                    <td>{item.percentage}%</td>
                                    <td>{item.name}</td>
                                    <td>
                                        {item.profit.toFixed(8)} {currency}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </td>
        </tr>
    )
}
