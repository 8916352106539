import { CButton, Dialog } from '@/shared/ui'
import style from './index.module.scss'
import { CInput } from '@/shared/ui/cabinet'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { setDialogs } from '@/store/sub_accounts'
import { useAppDispatch } from '@/store'
import { CONFIRM_EDIT_EMAIL, verificationSend } from '@/entities/cabinet'
import { useCode } from '@/shared/hooks'
import { useEffect } from 'react'
import { USER_INFO } from '@/shared/constants'

const WalletScheme = Yup.object().shape({
    code: Yup.string().required('Required'),
})

export function ConfirmEditEmailAlert({
    email,
    submitEmail,
}: {
    email: string
    submitEmail: (v: number) => void
}) {
    const dispatch = useAppDispatch()
    const { startTime, clearTimer, timer } = useCode()
    const userInfo = JSON.parse(localStorage.getItem(USER_INFO) || '{}')
    const formik = useFormik({
        initialValues: { code: '' },
        validationSchema: WalletScheme,
        validateOnChange: false,
        onSubmit: (values, formikHelpers) => {},
    })
    useEffect(() => {
        startTime()
        return () => {
            clearTimer()
        }
    }, [])
    return (
        <Dialog
            title={'e-mail verification '}
            onClose={() =>
                dispatch(setDialogs({ key: CONFIRM_EDIT_EMAIL, value: false }))
            }
            body={
                <div className={style.editWallet}>
                    <form
                        className={style.editWallet__form}
                        onSubmit={(e) => formik.handleSubmit(e)}
                    >
                        <div className={style.editWallet__form__prev}>
                            <span>New e-mail</span>
                            <p>{email}</p>
                        </div>
                        <CInput
                            error={formik.errors.code}
                            name={'code'}
                            onChange={formik.handleChange}
                            type={'number'}
                            label={'We have sent the code to your e-mail'}
                            placeholder={'Code'}
                        />
                        <div className={style.editWallet__form__warning}>
                            {timer === 0 ? (
                                <span
                                    onClick={() => {
                                        verificationSend({
                                            email: email,
                                            reversed: true,
                                            user_id: userInfo.user_id,
                                        }).then(() => {
                                            startTime()
                                        })
                                    }}
                                    className={
                                        style.editWallet__form__warning__btn
                                    }
                                >
                                    Reset
                                </span>
                            ) : (
                                <span>
                                    You can resend the code via: 00:{' '}
                                    {timer < 10 ? `0${timer}` : timer}
                                </span>
                            )}
                        </div>
                        <div className={style.editWallet__form__btn}>
                            <CButton
                                value={'Edit'}
                                type={'submit'}
                                onClick={() => {
                                    submitEmail(+formik.values.code)
                                }}
                                variant={'general'}
                            />
                        </div>
                    </form>
                </div>
            }
        />
    )
}
