import { useRef, useState } from 'react'
export * from './use-debounce'
export function useCode() {
    const [timer, setTimer] = useState(59)
    const myInterval = useRef<any>()

    const startTime = () => {
        setTimer(59)
        if (myInterval.current) {
            clearInterval(myInterval.current)
        }
        myInterval.current = setInterval(() => {
            setTimer((prev) => {
                if (prev <= 0) {
                    clearInterval(myInterval.current)
                    return 0
                }
                return prev - 1
            })
        }, 1000)
    }
    const clearTimer = () => clearInterval(myInterval.current)
    return {
        clearTimer,
        timer,
        startTime,
    }
}
