import axiosClient from '@/shared/libs/axiosClient'
import { IObserverLinkItem } from '@/entities/cabinet'
import { computeParams } from '@/shared/utils/to-params'

const enum API_URL {
    observerLink = '/api/v1/sub_accounts/observer_link/detail/',
    observerLinkSubAccounts = '/api/v1/sub_accounts/observer_link/sub_accounts/',
}
export function getItemsObserverLink(
    token: string,
    params?: {
        start_date: string
        end_date: string
        sub_account_name: string
        interval?: string
    }
) {
    return axiosClient
        .get<IObserverLinkItem>(
            API_URL.observerLink +
                `${token}/` +
                (params ? computeParams(params) : '')
        )
        .then((res) => res.data)
}

export function getObserverSubAccounts(
    token: string,
    params?: { start_date: string; end_date: string }
) {
    return axiosClient
        .get<IObserverLinkItem>(
            API_URL.observerLinkSubAccounts +
                `${token}/` +
                (params ? computeParams(params) : '')
        )
        .then((res) => res.data)
}
