import axiosClient from '@/shared/libs/axiosClient'
import { customRequest } from '@/shared/utils'
import { IFinancialResponse } from '@/entities/cabinet'

enum API_URL {
    all = '/api/v1/sub_accounts/api_key/all/',
    create = '/api/v1/sub_accounts/api_key/create/',
    delete = '/api/v1/sub_accounts/api_key/delete/',
    ENABLED_CODE = '/api/v1/otp/is_enabled/',
    CODE = '/api/v1/otp/verify_code/',
    CODE_ENABLE = '/api/v1/otp/enable/',
    TOKEN = '/api/v1/telegram_notification/token',
    GENERATE = '/api/v1/otp/qr_code/',
    SECRET_KEY = '/api/v1/otp/secret_key/',
    GET_FINANCE_API_KEY = '/api/v1/users/financial_api_key/',
}
export const getAllFinancialKeys = () => {
    return customRequest.getItem<IFinancialResponse[], any>(
        `${API_URL.GET_FINANCE_API_KEY}all/`
    )
}
export const addFinancialKeys = () => {
    return customRequest.post(API_URL.GET_FINANCE_API_KEY + 'create/')
}
export const deleteFinancialKeys = (payload: { public_key: string }) => {
    return customRequest.delete(
        API_URL.GET_FINANCE_API_KEY + 'delete/',
        payload
    )
}
export const getAllApiKey = (name: string) => {
    return customRequest.getItem(API_URL.all + `?sub_account_name=${name}`)
}
export const addApiKey = (name: string) => {
    return customRequest.post(API_URL.create, { sub_account_name: name })
}

export const getSecretKey = () => {
    return customRequest
        .getItem<any, { secret_key: string }>(API_URL.SECRET_KEY)
        .then((res) => res.data.secret_key)
}

export const telegramToken = () => {
    return customRequest
        .getItem(API_URL.TOKEN)
        .then((res) => (res.data as any).token)
}

export const deleteApiKey = (body: {
    api_key: string
    sub_account_name: string
}) => {
    return customRequest.delete(API_URL.delete, body)
}

export const checkCode = (code: string) => {
    return customRequest
        .getItem(API_URL.CODE + `?code=${code}`)
        .then((res) => res.data)
}

export const generateQrCode = () => {
    return customRequest.getItem(API_URL.GENERATE).then((res) => res.data)
}

export const qrCodeEnable = (code: string) => {
    return customRequest.post(API_URL.CODE_ENABLE, { code })
}

export const isQrCodeEnabled = () => {
    return customRequest.getItem(API_URL.ENABLED_CODE).then((res) => res.data)
}
