import { InputHTMLAttributes, useRef, useState } from 'react'
import classNames from 'classnames'

interface IProps extends InputHTMLAttributes<any> {
    error: any
}

export function PasswordInput(props: IProps) {
    const [showPassword, setShowPassword] = useState<boolean>(false)
    return (
        <div
            className={classNames(
                'form-group',
                'jsPasswordsWithEye',
                showPassword ? 'activedEye' : ''
            )}
        >
            <div className="password-input size-long">
                <input
                    className={classNames(
                        'form-control',
                        props.error ? 'error' : ''
                    )}
                    type={showPassword ? 'text' : 'password'}
                    {...props}
                />
                <i
                    onClick={() => {
                        setShowPassword(!showPassword)
                    }}
                >
                    <img
                        src={
                            require('@/shared/images/svg/password-eye.svg')
                                .default
                        }
                        alt=""
                    />
                </i>
            </div>
            {props.error && <span className={'error'}>{props.error}</span>}
        </div>
    )
}
