import axiosClient from '@/shared/libs/axiosClient'
import { ILoginDto, ISignupDto } from '@/entities'

const enum AUTH_API {
    login = '/api/v2/users/token/',
    signup = '/api/v1/users/register/',
    signUpWithReferral = '/api/v1/users/register/',
    refresh = '/api/v1/users/token/refresh/',
    VERIFY = '/api/v1/users/verification_code/verify/',
    CODE = '/api/v1/otp/verify_code/',
    VERIFY_SEND = '/api/v1/users/verification_code/send/',
    RESEND_EMAIL = '/api/v1/users/reset_password/send/',
    RESEND_CODE = '/api/v1/users/reset_password/verify/',
    RESEND_PASSWORD = '/api/v1/users/reset_password/',
    COUNTRIES = '/api/v1/users/countries/',
    FA_CODE = '/api/v2/users/token_with_otp_code/',
}

export function login(body: ILoginDto) {
    return axiosClient
        .post<{ access: string; refresh: string; oauth_token?: string }>(
            AUTH_API.login,
            body
        )
        .then((res) => res.data)
        .catch((error) => Promise.reject(error.response.data))
}

export function faCode(body: { oauth_token: string; otp_code: string }) {
    return axiosClient
        .post<{ access: string; refresh: string }>(AUTH_API.FA_CODE, body)
        .then((res) => res.data)
        .catch((error) => Promise.reject(error.response.data))
}

export function signUp(body: ISignupDto) {
    return axiosClient.post<{ success: boolean }>(AUTH_API.signup, body)
}

export function signUpWithReferralApi(body: ISignupDto, link: string) {
    return axiosClient.post<{ success: boolean }>(
        AUTH_API.signUpWithReferral + `${link}/`,
        body
    )
}

export function getCountriesApi() {
    return axiosClient.get(AUTH_API.COUNTRIES).then((res) => res.data)
}

export function refreshToken(refresh: string) {
    return axiosClient
        .post<{ access: string; refresh: string }>(AUTH_API.refresh, {
            refresh,
        })
        .then((res) => res.data)
}
export const verifySend = (email: string) => {
    return axiosClient.post(AUTH_API.VERIFY_SEND, { email })
}

export const verifyCode = (code: string, email: string) => {
    return axiosClient.post(AUTH_API.VERIFY, { code, email })
}

export const resendPasswordEmail = (email: string) => {
    return axiosClient.post(AUTH_API.RESEND_EMAIL, { email })
}

export const resendPasswordCode = (body: { email: string; code: string }) => {
    return axiosClient.post(AUTH_API.RESEND_CODE, body).then((res) => res.data)
}

export const resendPassword = (body: {
    email: string
    reset_token: string
    password: string
    password2: string
}) => {
    return axiosClient.post(AUTH_API.RESEND_PASSWORD, body)
}
export const checkCode = (code: string) => {
    return axiosClient
        .get(AUTH_API.CODE + `?code=${code}`)
        .then((res) => res.data)
}
