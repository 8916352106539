import React from 'react'
import style from './index.module.scss'
import classNames from 'classnames'

interface IProps extends React.ButtonHTMLAttributes<any> {
    value: string
    appendIcon?: any
    variant?: 'outlined' | 'normal' | 'general' | 'text'
}

export function CButton({
    value,
    appendIcon,
    variant = 'outlined',
    className,
    ...props
}: IProps) {
    return (
        <button
            className={classNames(
                style.btn,
                appendIcon ? style.middle : '',
                style[variant],
                props.disabled ? style.disabled : '',
                className
            )}
            {...props}
        >
            {!appendIcon ? (
                value
            ) : (
                <>
                    <span>{value}</span>
                    {appendIcon}
                </>
            )}
        </button>
    )
}
