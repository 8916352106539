import React from 'react'
import { Alert } from '@/shared/ui'

class ErrorBoundary extends React.Component {
    constructor(props: any) {
        super(props)
        this.state = { hasError: false }
    }

    componentDidCatch(error: any, info: any) {
        this.setState({ hasError: true })
    }

    render() {
        if ((this.state as any).hasError) {
            return <Alert title={'ERROR'} description={'error description'} />
        }

        return (this.props as any).children
    }
}

export { ErrorBoundary }
