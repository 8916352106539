import { useContext, useEffect, useState } from 'react'
import { ISubAccountInfoDto, useSubAccount } from '@/entities/cabinet'
import { useDebounce } from '@/shared/hooks'
import { GlobalContext } from '@/entities'

export function useSubaccountSearch() {
    const { subAccounts, currentSubAccount } = useSubAccount()
    const [search, setSearch] = useState('')
    const { currency } = useContext(GlobalContext)
    const changeSearchVal = useDebounce(search, 250)

    const [cachedSubAccounts, setCachedSubAccounts] = useState<
        ISubAccountInfoDto[]
    >([])
    useEffect(() => {
        setCachedSubAccounts((prev) =>
            subAccounts.filter((subAccount) =>
                subAccount.name
                    .toLowerCase()
                    .includes(changeSearchVal.toLowerCase())
            )
        )
        setCachedSubAccounts((prev) =>
            [...prev].filter((account) =>
                currency === 'all' ? true : account.crypto_currency === currency
            )
        )
    }, [changeSearchVal])
    useEffect(() => {
        setCachedSubAccounts(
            subAccounts.filter((account) =>
                currency === 'all' ? true : account.crypto_currency === currency
            )
        )
    }, [subAccounts])

    useEffect(() => {
        setCachedSubAccounts((prev) =>
            [...subAccounts].filter((account) =>
                currency === 'all' ? true : account.crypto_currency === currency
            )
        )
    }, [currency])

    return {
        cachedSubAccounts,
        search,
        setCachedSubAccounts,
        subAccounts,
        setSearch,
        currentSubAccount,
    }
}
