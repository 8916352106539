import style from './index.module.scss'
import { createPortal } from 'react-dom'
import classNames from 'classnames'
import { Icon } from '@/shared/ui'

interface IProps {
    title: string
    body: any
    variant?: 'large' | 'medium' | 'tiny'
    onClose?: () => void
}

export function Dialog({
    title,
    body,
    variant = 'large',
    onClose = () => {},
}: IProps) {
    return createPortal(
        <div className={style.dialog}>
            <div className={classNames(style.dialog__wrapper, style[variant])}>
                <h2>
                    {title}
                    <span onClick={() => onClose()}>
                        <Icon type={'close'} />
                    </span>
                </h2>
                <div className={style.dialog__wrapper__content}>{body}</div>
            </div>
        </div>,
        document.body
    )
}
