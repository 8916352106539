export const GET_ALL_SUB_ACCOUNTS = 'subaccount/all'
export const UPDATE_SUB_ACCOUNT_REWARD = 'subaccount/update_reward'
export const UPDATE_SUB_ACCOUNT_WALLET = 'subaccount/UPDATE_SUB_ACCOUNT_WALLET'
export const GET_USER_DETAILS = 'subaccount/GET_USER_DETAILS'
export const CREATE_SUB_ACCOUNT = 'subaccount/create'
export const GET_SUB_ACCOUNT_SUMMARY = 'subaccount/summary'
export const GET_SUB_ACCOUNT_PAYOUT = 'subaccount/payouts'
export const GET_SUB_ACCOUNT_STATISTICS = 'subaccount/statistics'
export const GET_SUB_ACCOUNT_WORKER = 'subaccount/workers'
export const GET_SUB_ACCOUNT_STRATUM = 'subaccount/stratum'
export const GET_SUB_ACCOUNT_EARNINGS = 'subaccount/earnings'
export const GET_STATISTICS = 'statistics'
export const GET_SUB_ACCOUNT_CHART = 'subaccount/charts'
