import { Outlet } from 'react-router-dom'
import '@/styles/login.scss'
export * from './error-boundary'
export function AuthLayout() {
    return (
        <div className="body-page">
            <div className="main-page-holder">
                <div className="all-page-wrapper">
                    <div className="all-page-holder">
                        <Outlet />
                    </div>
                </div>
            </div>
        </div>
    )
}
