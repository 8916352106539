import { CButton, Dialog, Icon } from '@/shared/ui'
import { CInput, Select } from '@/shared/ui/cabinet'
import { RootState, useAppDispatch } from '@/store'
import {
    createSubAccount$,
    getAllSubAccounts$,
    setDialogs,
} from '@/store/sub_accounts'
import { ADD_SUBACCOUNT } from '@/entities/cabinet'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useEffect, useState } from 'react'
import style from './index.module.scss'
import { useSelector } from 'react-redux'
import { getCurrencies$, getEarningScheme$ } from '@/store/references'
import { useTranslations } from 'use-intl'

const SubAccountScheme = Yup.object().shape({
    name: Yup.string().required('Fill field'),
    crypto_currency: Yup.string().required('Fill field'),
    wallet_address: Yup.string().required('Fill field'),
    earning_scheme_id: Yup.string().required('Fill field'),
})

export function AddSubaccountDialog() {
    const dispatch = useAppDispatch()
    const [error, setError] = useState<string | null>(null)
    const currencies = useSelector(
        (state: RootState) => state.references.currencies
    )
    const schemes = useSelector(
        (state: RootState) => state.references.earningScheme
    )
    const [rewardOpened, setRewardOpened] = useState(false)
    const [virtualSubAccounts, setVirtualSubAccounts] = useState([
        {
            name: '',
            percentage: 0,
            wallet_address: '',
            id: 0,
        },
    ])
    const i18n = useTranslations('cabinet.main.dialog')
    const formik = useFormik({
        initialValues: {
            name: '',
            crypto_currency: '',
            wallet_address: '',
            earning_scheme_id: 0,
        },
        validateOnChange: false,
        validationSchema: SubAccountScheme,
        onSubmit: (values: any, actions) => {
            if (!rewardOpened) {
                delete values.virtual_sub_accounts
            } else {
                values.virtual_sub_accounts = virtualSubAccounts.map(
                    (virtual: any) => {
                        const obj = { ...virtual }
                        delete obj.id
                        return obj
                    }
                )
            }
            setError('')
            dispatch(
                createSubAccount$({
                    ...values,
                })
            )
                .unwrap()
                .then(() => {
                    dispatch(setDialogs({ key: ADD_SUBACCOUNT, value: false }))
                    actions.setSubmitting(false)
                    dispatch(getAllSubAccounts$())
                })
                .catch((e) => {
                    setError(e.message || '')
                    actions.setSubmitting(false)
                })
        },
    })
    useEffect(() => {
        dispatch(getEarningScheme$())
            .unwrap()
            .then((res) => {
                if (res.length) {
                    formik.values.earning_scheme_id = res[0].id
                }
            })
        dispatch(getCurrencies$())
            .unwrap()
            .then((res) => {
                if (res.length) {
                    formik.values.crypto_currency = res[0]
                }
            })
    }, [])

    return (
        <Dialog
            title={i18n('title')}
            onClose={() =>
                dispatch(setDialogs({ key: ADD_SUBACCOUNT, value: false }))
            }
            body={
                <>
                    <form
                        onSubmit={(e) => {
                            e.preventDefault()
                            formik.handleSubmit(e)
                        }}
                        className={style.addSubaccount__form}
                    >
                        <div className={style.addSubaccount__form__info}>
                            <CInput
                                label={i18n('name')}
                                name={'name'}
                                placeholder={i18n('name')}
                                error={formik.errors.name}
                                value={formik.values.name}
                                onChange={formik.handleChange}
                            />
                            <CInput
                                error={formik.errors.wallet_address}
                                placeholder={i18n('wallet_address')}
                                label={i18n('wallet_address')}
                                value={formik.values.wallet_address}
                                name={'wallet_address'}
                                onChange={formik.handleChange}
                            />
                            <Select
                                error={formik.errors.crypto_currency?.toString()}
                                placeholder={i18n('choose_currency')}
                                label={i18n('choose_currency')}
                                value={formik.values.crypto_currency}
                                options={currencies.map((currency) => ({
                                    key: currency,
                                    value: currency,
                                }))}
                                name={'crypto_currency'}
                                onChange={formik.handleChange}
                            />
                            <Select
                                error={formik.errors.earning_scheme_id?.toString()}
                                placeholder={i18n('choose_scheme')}
                                label={i18n('choose_scheme')}
                                value={formik.values.earning_scheme_id}
                                options={schemes.map((currency) => ({
                                    key: currency.id,
                                    value: currency.earning_scheme,
                                }))}
                                name={'earning_scheme_id'}
                                onChange={formik.handleChange}
                            />
                        </div>
                        <div className={style.addSubaccount__form__optional}>
                            <div
                                className={
                                    style.addSubaccount__form__optional__title
                                }
                                onClick={() => setRewardOpened(!rewardOpened)}
                            >
                                <span>{i18n('reward')}</span>
                                <img
                                    src={
                                        require(
                                            rewardOpened
                                                ? '@/shared/images/svg/Tick.svg'
                                                : '@/shared/images/svg/circle.svg'
                                        ).default
                                    }
                                    alt="tick"
                                />
                            </div>
                            {rewardOpened && (
                                <div
                                    className={
                                        style.addSubaccount__form__optional__table
                                    }
                                >
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>{i18n('split')}</th>
                                                <th>{i18n('account')}</th>
                                                <th>{i18n('wallet')}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {virtualSubAccounts.map(
                                                (virtual: any, idx: number) => (
                                                    <tr key={virtual.id}>
                                                        <td>
                                                            <div
                                                                className={
                                                                    style.flex
                                                                }
                                                            >
                                                                <CInput
                                                                    error={null}
                                                                    name={
                                                                        'percentage'
                                                                    }
                                                                    placeholder={
                                                                        '0'
                                                                    }
                                                                    type={
                                                                        'number'
                                                                    }
                                                                    onChange={(
                                                                        e: any
                                                                    ) => {
                                                                        virtual.percentage =
                                                                            e.target.value
                                                                    }}
                                                                />{' '}
                                                                %
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <CInput
                                                                error={''}
                                                                placeholder={
                                                                    'Name'
                                                                }
                                                                name={'name'}
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    virtual.name =
                                                                        e.target.value
                                                                }}
                                                            />
                                                        </td>
                                                        <td>
                                                            <CInput
                                                                error={''}
                                                                placeholder={
                                                                    'Wallet'
                                                                }
                                                                name={
                                                                    'wallet_address'
                                                                }
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    virtual.wallet_address =
                                                                        e.target.value
                                                                }}
                                                            />
                                                        </td>
                                                        <td>
                                                            <img
                                                                src={
                                                                    require('@/shared/images/svg/trash.svg')
                                                                        .default
                                                                }
                                                                alt=""
                                                                onClick={() => {
                                                                    setVirtualSubAccounts(
                                                                        (
                                                                            prev
                                                                        ) => {
                                                                            return virtualSubAccounts.filter(
                                                                                (
                                                                                    item
                                                                                ) =>
                                                                                    item.id !==
                                                                                    virtual.id
                                                                            )
                                                                        }
                                                                    )
                                                                }}
                                                            />
                                                        </td>
                                                    </tr>
                                                )
                                            )}
                                        </tbody>
                                    </table>
                                    <div
                                        className={
                                            style.addSubaccount__form__optional__add
                                        }
                                    >
                                        <CButton
                                            className={style.tiny}
                                            value={i18n('add')}
                                            type={'button'}
                                            onClick={() => {
                                                setVirtualSubAccounts(
                                                    (prev) => {
                                                        return [
                                                            ...prev,
                                                            {
                                                                name: '',
                                                                percentage: 0,
                                                                id: prev.length
                                                                    ? prev[
                                                                          prev.length -
                                                                              1
                                                                      ].id + 1
                                                                    : 1,
                                                                wallet_address:
                                                                    '',
                                                            },
                                                        ]
                                                    }
                                                )
                                            }}
                                            appendIcon={<Icon type={'plus'} />}
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                        {error && (
                            <span className={style.addSubaccount__form__error}>
                                {error}
                            </span>
                        )}
                        <div className={style.addSubaccount__form__btn}>
                            <CButton
                                value={i18n('createBtn')}
                                type={'submit'}
                                variant={'general'}
                            />
                        </div>
                    </form>
                </>
            }
        />
    )
}
