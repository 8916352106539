import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import { USER_TOKEN } from '@/shared/constants'
// TODO: remove from shared state
import { refreshToken } from '@/entities'

export * from './debounce'
export * from './to-hashrate'
export * from './to-date'
export * from './download-xls'

const customFetch = axios.create({
    withCredentials: true,
    timeout: 1000000,
    headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
    },
})
if (!process.env.REACT_APP_DEV) {
    customFetch.defaults.baseURL = process.env.REACT_APP_URL
}
customFetch.interceptors.request.use(
    async (config) => {
        const tokenStorage = localStorage.getItem(USER_TOKEN)
        const currentToken = tokenStorage ? JSON.parse(tokenStorage) : {}
        if (currentToken) {
            config.headers['Authorization'] = `Bearer ${currentToken.access}`
        }
        return config
    },
    (error) => {
        return Promise.reject(error)
    }
)
customFetch.interceptors.response.use(
    (response) => {
        return response
    },
    async function (error) {
        const originalRequest = error.config
        if (
            [403, 401].includes(error.response.status) &&
            !originalRequest._retry
        ) {
            originalRequest._retry = true

            const tokenStorage = localStorage.getItem(USER_TOKEN)
            const currentToken = tokenStorage ? JSON.parse(tokenStorage) : {}
            const resp = await refreshToken(currentToken.refresh)

            localStorage.setItem(USER_TOKEN, JSON.stringify(resp))
            customFetch.defaults.headers.common['Authorization'] =
                `Bearer ${resp.access}`
            return customFetch(originalRequest)
        }
        return Promise.reject(error)
    }
)

const customRequest = {
    getItem<T, M>(
        url: string,
        params: string = '',
        config: AxiosRequestConfig = {}
    ) {
        return customFetch.get<T, AxiosResponse<M>>(url + params, config)
    },
    patch<T, M>(url: string, body: T, params?: any) {
        return customFetch.patch<T, M>(url, body, {
            headers: {
                ...(params?.headers ? { ...params.headers } : {}),
            },
        })
    },
    post<T, M>(url: string, body?: T, params?: any) {
        return customFetch.post<T, M>(url, body, {
            headers: {
                ...(params?.headers ? { ...params.headers } : {}),
            },
        })
    },
    delete<T>(url: string, body?: T) {
        return customFetch.delete<T>(url, {
            ...(body ? { data: body } : {}),
        })
    },
}
export { customRequest, customFetch }
