import React from 'react'
import { RouterProvider } from 'react-router-dom'
import { router } from '@/app/router'
import { GlobalProvider } from '@/entities'

function App() {
    return (
        <GlobalProvider>
            <RouterProvider router={router} />
        </GlobalProvider>
    )
}

export default App
