import { Dialog } from '@/shared/ui'
import { DayPicker } from 'react-day-picker'
import './index.module.scss'
import 'react-day-picker/dist/style.css'
import { useAppDispatch } from '@/store'
import { setDialogs } from '@/store/sub_accounts'
import { CHOOSE_DATE } from '@/entities/cabinet'
import { useTranslations } from 'use-intl'

export function DatePickAlert({
    range,
    setRange,
}: {
    range: any
    setRange: any
}) {
    const dispatch = useAppDispatch()
    const i18n = useTranslations('cabinet.history')

    return (
        <Dialog
            title={i18n('dateTitle')}
            onClose={() => {
                dispatch(
                    setDialogs({
                        key: CHOOSE_DATE,
                        value: false,
                    })
                )
            }}
            body={
                <>
                    <style>{`
                        .rdp-months {
                          width: 100%;
                          text-align: center;
                          justify-content: center;
                        }
                        .rdp {
                          --rdp-cell-size: 50px; /* Size of the day cells. */
                          --rdp-caption-font-size: 18px; /* Font size for the caption labels. */
                          --rdp-accent-color: var(--calendar-background); /* Accent color for the background of selected days. */
                          --rdp-background-color: #40C067; /* Background color for the hovered/focused elements. */
                          --rdp-outline: 2px solid var(--rdp-accent-color); /* Outline border for focused elements */
                          --rdp-accent-color-dark: #fff;
                          --rdp-outline-selected: 3px solid var(--rdp-accent-color); /* Outline border for focused _and_ selected elements */
                          --rdp-selected-color: #7C86A2; /* Color of selected day text */
                        }
                    `}</style>
                    <DayPicker
                        id="test"
                        mode="range"
                        defaultMonth={new Date()}
                        selected={range}
                        onSelect={setRange}
                    />
                </>
            }
            variant={'medium'}
        />
    )
}
