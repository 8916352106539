import { useSelector } from 'react-redux'
import { RootState } from '@/store'

export function useSubAccount() {
    const currentSubAccount = useSelector(
        (state: RootState) => state.subAccount.currentSubAccount
    )
    const currentSubAccountInfo = useSelector(
        (state: RootState) => state.subAccount.subAccountInfo
    )
    const loading = useSelector((state: RootState) => state.subAccount.loading)
    const subAccounts = useSelector(
        (state: RootState) => state.subAccount.subAccounts
    )
    return {
        currentSubAccount,
        currentSubAccountInfo,
        subAccounts,
        loading,
    }
}
