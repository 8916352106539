import { customRequest } from '@/shared/utils'
import { computeParams } from '@/shared/utils/to-params'
import { downloadXls } from '@/shared/utils/download-xls'

const enum API_URL {
    earningXls = '/api/v1/sub_accounts/earnings/xls/',
    payoutXls = '/api/v1/sub_accounts/payouts/xls/',
}
export function downloadXlsEarnings(params: {
    sub_account_name: string
    start_date: string | null
    end_date: string | null
}) {
    return customRequest
        .getItem(API_URL.earningXls, computeParams(params), {
            responseType: 'blob',
        })
        .then((response: any) => downloadXls(response))
}

export function downloadXlsPayout(params: {
    sub_account_name: string
    start_date: string | null
    end_date: string | null
}) {
    return customRequest
        .getItem(API_URL.payoutXls, computeParams(params), {
            responseType: 'blob',
        })
        .then((response: any) => downloadXls(response))
}
