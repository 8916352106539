import classNames from 'classnames'
import style from './index.module.scss'
import { Fragment, useState } from 'react'

interface IProps {
    headers: any[]
    columns: {
        expandable?: boolean
        expandedContent?: any
        data: Array<any>
        onExpandedIconClick?: (id: number) => void
    }[]
    className?: string
}

export function Table(props: IProps) {
    const { headers, columns, className } = props
    const [expanded, setExpanded] = useState<{ [key in string]: boolean }>({})
    return (
        <div className={style.wrapper}>
            <table
                className={classNames(style.table, className ? className : '')}
            >
                <thead className={style.table__headers}>
                    <tr>
                        {columns.some((row) => row.expandable) && (
                            <th className={style.icon}></th>
                        )}
                        {headers.map((name, idx) => {
                            return (
                                <th
                                    key={idx}
                                    className={!name ? style.icon : ''}
                                >
                                    {name}
                                </th>
                            )
                        })}
                    </tr>
                </thead>
                <tbody className={style.table__columns}>
                    {columns.map((items, idx) => {
                        return (
                            <Fragment key={idx}>
                                <tr className={style.table__columns__row}>
                                    {items.expandable && (
                                        <td className={style.icon}>
                                            <img
                                                onClick={() => {
                                                    setExpanded((prev) => ({
                                                        ...prev,
                                                        [idx]: !expanded[idx],
                                                    }))
                                                    items.onExpandedIconClick &&
                                                        items.onExpandedIconClick(
                                                            idx
                                                        )
                                                    if (
                                                        items.onExpandedIconClick
                                                    ) {
                                                        setExpanded((prev) => {
                                                            Object.keys(
                                                                prev
                                                            ).forEach((key) => {
                                                                prev[key] =
                                                                    false
                                                            })
                                                            return { ...prev }
                                                        })
                                                        setExpanded((prev) => ({
                                                            ...prev,
                                                            [idx]: !expanded[
                                                                idx
                                                            ],
                                                        }))
                                                    }
                                                }}
                                                src={
                                                    require('@/shared/images/svg/bottom-arrow.svg')
                                                        .default
                                                }
                                                alt={'bottom arrow'}
                                            />
                                        </td>
                                    )}
                                    {items.data.map((item, index) => {
                                        return (
                                            <td
                                                key={index}
                                                className={
                                                    !headers[index]
                                                        ? style.icon
                                                        : ''
                                                }
                                            >
                                                {item}
                                            </td>
                                        )
                                    })}
                                </tr>
                                {expanded[idx] && items.expandedContent}
                            </Fragment>
                        )
                    })}
                    {!columns.length && (
                        <tr className={style.table__columns__empty}>
                            <td
                                colSpan={headers.length}
                                className={style.empty}
                            >
                                Empty
                            </td>{' '}
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    )
}
