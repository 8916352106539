import { CButton, Dialog } from '@/shared/ui'
import style from './index.module.scss'
import { useAppDispatch } from '@/store'
import { setDialogs } from '@/store/sub_accounts'
import { ADD_SUBACCOUNT, CREATE_SUBACCOUNT } from '@/entities/cabinet'

export function CreateSubAccountAlert() {
    const dispatch = useAppDispatch()
    return (
        <Dialog
            title={''}
            onClose={() => {
                dispatch(setDialogs({ key: CREATE_SUBACCOUNT, value: false }))
            }}
            body={
                <div className={style.createSubAccount}>
                    <div>
                        <img
                            src={
                                require('@/cabinet/images/svg/create-subaccount.svg')
                                    .default
                            }
                            alt={'create subaccount'}
                        />
                    </div>
                    <h2>You don’t have subaccounts</h2>
                    <p>
                        In order to start mining, you need to create at least 1
                        subaccount
                    </p>
                    <CButton
                        className={style.createSubAccount__btn}
                        value={'Create Subaccount'}
                        variant={'general'}
                        onClick={() => {
                            dispatch(
                                setDialogs({
                                    key: CREATE_SUBACCOUNT,
                                    value: false,
                                })
                            )
                            dispatch(
                                setDialogs({ key: ADD_SUBACCOUNT, value: true })
                            )
                        }}
                    />
                </div>
            }
        />
    )
}
