import { IUser, refreshToken } from '@/entities'
import { decodeToken } from 'react-jwt'
import { USER_INFO, USER_TOKEN } from '@/shared/constants'
import { CURRENT_SUBACCOUNT_KEY } from '@/entities/cabinet'

export function useAuth() {
    const setUserStore = (token: { access: string; refresh: string }) => {
        const data: IUser | null = decodeToken(token.access)
        if (data) {
            data.created_date = new Date().toISOString()
        }
        localStorage.setItem(USER_INFO, JSON.stringify(data))
        localStorage.setItem(USER_TOKEN, JSON.stringify(token))
        return Promise.resolve(data)
    }

    const logout = () => {
        localStorage.removeItem(USER_TOKEN)
        localStorage.removeItem(USER_INFO)
        localStorage.removeItem(CURRENT_SUBACCOUNT_KEY)

        return Promise.resolve(true)
    }

    return {
        setUserStore,
        logout,
    }
}

export function useRefreshToken() {
    const refreshIt = (token: string) => {
        return refreshToken(token).then((res) => {
            localStorage.setItem(USER_TOKEN, JSON.stringify(res))
        })
    }

    return {
        refreshIt,
    }
}
