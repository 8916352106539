import style from './index.module.scss'
import { toTextDate } from '@/shared/utils/to-text-date'
import { useTheme } from '@/features'
export function DatePicker({
    date,
    onClick = () => {},
}: {
    date: Date
    onClick?: () => void
}) {
    const { currentTheme } = useTheme()
    return (
        <div className={style.datePicker} onClick={() => onClick()}>
            <div className={style.datePicker__wrapper}>
                <img
                    src={
                        currentTheme === 'dark'
                            ? require('@/shared/images/calendar-light.png')
                            : require('@/shared/images/calendar.png')
                    }
                    alt="calendar"
                />
                <span>{toTextDate(date)}</span>
            </div>
        </div>
    )
}
