import React, { InputHTMLAttributes, useRef, useState } from 'react'
import classNames from 'classnames'
import style from './index.module.scss'

interface IProps extends InputHTMLAttributes<any> {
    error: any
    label?: string
    appendIcon?: React.ReactNode
}

function Component({ className, appendIcon, ...props }: IProps) {
    const [showPassword, setShowPassword] = useState(false)
    const ref = useRef<HTMLInputElement>(null)
    const changePassword = () => {
        if (ref.current && !showPassword) {
            ref.current.type = 'text'
        } else if (ref.current) {
            ref.current.type = 'password'
        }
        setShowPassword((prev) => !prev)
    }
    return (
        <div className={style.wrapper}>
            {props.label && <label htmlFor={props.id}>{props.label}</label>}
            <div>
                <input
                    type="password"
                    className={classNames(
                        props.error ? 'error' : '',
                        style.input,
                        style.padding,
                        className
                    )}
                    ref={ref}
                    {...props}
                />
                <img
                    src={
                        showPassword
                            ? require(`@/cabinet/images/svg/show-password.svg`)
                                  .default
                            : require(`@/cabinet/images/svg/hide-password.svg`)
                                  .default
                    }
                    onClick={changePassword}
                    alt={'password icon'}
                />
            </div>
            {props.error && <span className={style.error}>{props.error}</span>}
        </div>
    )
}

const CPasswordInput = React.memo(Component)
export { CPasswordInput }
