import { useEffect, useState } from 'react'
import { useChart, useSubAccount } from '@/entities/cabinet'
import { SpinnerLoader } from '@/shared/ui/SpinnerLoader'
import { CustomChart } from '@/shared/ui/cabinet/ui/chart'

export const CHART_INTERVALS: {
    [key in string]: { key: string; label: string }
} = {
    oneHour: {
        key: '1HOUR',
        label: '1hour',
    },
    oneDay: {
        key: '24HOUR',
        label: '24hour',
    },
    oneMonth: {
        key: '30DAY',
        label: '30day',
    },
}

export function CabinetChartComponent({
    interval,
    getSubAccountChart,
}: {
    interval: { key: string; label: string }
    getSubAccountChart: (name: string, interval: string) => any
}) {
    const { currentSubAccount } = useSubAccount()
    const { chartState, setChartStateByInterval } = useChart()
    const [loadingChart, setLoadingChart] = useState(true)

    useEffect(() => {
        if (currentSubAccount) {
            getChartByInterval(interval.key)
        }
    }, [currentSubAccount, interval])
    const getChartByInterval = (interval: string) => {
        setLoadingChart(true)
        getSubAccountChart(currentSubAccount, interval)
            .then((res: any) => {
                setChartStateByInterval(res, interval)
            })
            .catch(() => {})
            .finally(() => setLoadingChart(false))
    }
    return (
        <>
            {loadingChart ? (
                <SpinnerLoader />
            ) : (
                <CustomChart
                    options={chartState.options as any}
                    series={chartState.series}
                />
            )}
        </>
    )
}
