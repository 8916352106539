import React, { InputHTMLAttributes } from 'react'
import classNames from 'classnames'

interface IProps extends InputHTMLAttributes<any> {
    error: any
}

function Component(props: IProps) {
    return (
        <div className="form-group">
            <input
                type="text"
                className={classNames(
                    'form-control',
                    props.error ? 'error' : ''
                )}
                {...props}
            />
            {props.error && <span className={'error'}>{props.error}</span>}
        </div>
    )
}

const Input = React.memo(Component)
export { Input }
