import style from './index.module.scss'
import { Card, Icon } from '@/shared/ui'
import classNames from 'classnames'
import { useNavigate } from 'react-router-dom'
import { LOGOUT_DIALOG } from '@/entities/cabinet'
import { LogoutDialog } from '@/features'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from '@/store'
import { setDialogs } from '@/store/sub_accounts'
import { useTranslations } from 'use-intl'

const GENERAL_SETTINGS = [
    {
        text: 'personal',
        type: 'settings',
        color: '#40C067',
        url: '/cabinet/settings?page=personal',
    },
    {
        text: 'notification',
        type: 'notification',
        color: '#40C067',
        url: '/cabinet/settings?page=notification',
    },
    {
        text: '2fa',
        type: '2fa',
        color: '#40C067',
        url: '/cabinet/settings?page=2fa',
    },
    {
        text: 'financial_api',
        type: 'api',
        color: '#40C067',
        url: '/cabinet/settings?page=finance_api',
    },
    {
        text: 'language',
        type: 'language',
        color: '#40C067',
        url: '/cabinet/settings?page=language',
    },
]

const SUB_ACCOUNT_SETTINGS = [
    {
        text: 'observers',
        type: 'observer',
        color: '#40C067',
        url: '/cabinet/observers',
    },
    {
        text: 'workers',
        color: '#40C067',
        type: 'worker',
        url: '/cabinet/workers',
    },
    {
        text: 'subaccounts',
        color: '#40C067',
        type: 'subaccounts',
        url: '/cabinet/subaccounts',
    },
    {
        text: 'referral',
        color: '#40C067',
        type: 'referal',
        url: '/cabinet/referal',
    },
    {
        text: 'api',
        type: 'api',
        color: '#40C067',
        url: '/cabinet/settings?page=api',
    },
    {
        text: 'logout',
        type: 'logout',
        color: '#AEAEB7',
        url: '',
    },
]

export function CabinetMobileSettings() {
    const navigate = useNavigate()
    const dialogs = useSelector((state: RootState) => state.subAccount.dialogs)
    const dispatch = useAppDispatch()

    const i18n = useTranslations('cabinet.mobile')
    return (
        <div className={style.settings}>
            <h1>{i18n('title')}</h1>
            <div className={style.settings__list}>
                {GENERAL_SETTINGS.map((set, idx) => (
                    <Card
                        className={style.settings__list__item}
                        key={idx}
                        onClick={() => navigate(set.url)}
                    >
                        <div className={style.settings__list__item__icon}>
                            <Icon type={set.type as any} color={set.color} />
                        </div>
                        <p>{i18n(set.text)}</p>
                    </Card>
                ))}
            </div>
            <h1>{i18n('subaccountTitle')}</h1>
            <div className={style.settings__list}>
                {SUB_ACCOUNT_SETTINGS.map((set, idx) => (
                    <Card
                        className={style.settings__list__item}
                        key={idx}
                        onClick={() =>
                            set.type !== 'logout'
                                ? navigate(set.url)
                                : dispatch(
                                      setDialogs({
                                          key: LOGOUT_DIALOG,
                                          value: true,
                                      })
                                  )
                        }
                    >
                        <div
                            className={classNames(
                                style.settings__list__item__icon,
                                set.type === 'logout' ? style.logout : ''
                            )}
                        >
                            <Icon type={set.type as any} color={set.color} />
                        </div>
                        <p>{i18n(set.text)}</p>
                    </Card>
                ))}
            </div>
            {dialogs[LOGOUT_DIALOG] && <LogoutDialog />}
        </div>
    )
}
