import { useAppDispatch } from '@/store'
import { Dialog, Icon } from '@/shared/ui'
import { setDialogs } from '@/store/sub_accounts'
import { ADD_FINANCIAL_KEY } from '@/entities/cabinet'
import style from '@/features/cabinet/ui/cabinet-alert-components/index.module.scss'
import { toast } from 'react-toastify'

export function AddFinancialKeyAlert({ secretKey }: { secretKey: string }) {
    const dispatch = useAppDispatch()
    return (
        <Dialog
            title={'Secret Key'}
            onClose={() =>
                dispatch(setDialogs({ key: ADD_FINANCIAL_KEY, value: false }))
            }
            body={
                <div className={style.financial}>
                    <div className={style.financial__title}>
                        <p className={style.financial__subtitle}>{secretKey}</p>
                        <div
                            onClick={() => {
                                navigator.clipboard.writeText(secretKey).then(
                                    function () {},
                                    function () {}
                                )
                                toast.success('Copied')
                            }}
                        >
                            <Icon type={'copy'} />
                        </div>
                    </div>
                </div>
            }
        />
    )
}
