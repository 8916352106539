import style from './index.module.scss'
import { useState } from 'react'

interface IProps {
    options: { key: string; value: string; checked?: boolean }[]
    label?: string
    onSelect: (key: any) => void
}

export function CustomDropdown({ options, onSelect, label }: IProps) {
    const [opened, setOpened] = useState(false)
    return (
        <div className={style.customDropdown}>
            <label>{label || 'Subaccounts'}</label>
            <div
                className={style.customDropdown__input}
                onClick={() => setOpened(!opened)}
            >
                <span>
                    {options
                        .filter((option) => option.checked)
                        .map((res) => res.value)
                        .toString()}
                </span>
                <img
                    src={
                        require('@/shared/images/svg/bottom-arrow.svg').default
                    }
                    alt=""
                    className={opened ? style.rotate : ''}
                />
            </div>
            {opened && (
                <div className={style.customDropdown__list}>
                    {options.map((option, id) => {
                        return (
                            <div
                                className={style.customDropdown__list__item}
                                key={id}
                                onClick={() => onSelect(option)}
                            >
                                <span>{option.value}</span>
                                <div>
                                    <img
                                        src={
                                            require(
                                                option.checked
                                                    ? '@/shared/images/svg/Tick.svg'
                                                    : '@/shared/images/svg/circle.svg'
                                            ).default
                                        }
                                        alt=""
                                    />
                                </div>
                            </div>
                        )
                    })}
                </div>
            )}
        </div>
    )
}
