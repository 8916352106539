import { useTranslations } from 'use-intl'
import style from './index.module.scss'
import Logo from '@/shared/ui/Logo'
import { Link } from 'react-router-dom'
export function Footer() {
    const i18n = useTranslations('main.footer')
    return (
        <footer className={style.footer}>
            <div className={style.footer__container}>
                <div className={style.footer__inner}>
                    <div className={style.footer__inner__logo}>
                        <Logo />
                        <div
                            className={
                                style.footer__inner__logo__buttons_wrapper
                            }
                        >
                            <a
                                className={style.active}
                                href="mailto:info@21pool.io"
                            >
                                {i18n('contact_us')}
                            </a>
                            <Link to="/auth/login">{i18n('log_in')}</Link>
                        </div>

                        <p>
                            {i18n('copyright.part1')} &copy;{' '}
                            {`${new Date().getFullYear()} `}
                            {i18n('copyright.part2')}
                        </p>
                    </div>
                    <div className={style.footer__inner__links}>
                        <h1>{i18n('content')}</h1>
                        <ul>
                            {window.location.pathname === '/' ? (
                                <>
                                    <li>
                                        <a href="#about">{i18n('about')}</a>
                                    </li>
                                    <li>
                                        <a href="#calculator">
                                            {i18n('calculator')}
                                        </a>
                                    </li>
                                </>
                            ) : (
                                <>
                                    <li>
                                        <Link to="/#about">
                                            {i18n('about')}
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/#calculator">
                                            {i18n('calculator')}
                                        </Link>
                                    </li>
                                </>
                            )}

                            <li>
                                <Link to="/faq">{i18n('faq')}</Link>
                            </li>
                        </ul>
                    </div>
                    <div className={style.footer__inner__links}>
                        <h1>{i18n('support')}</h1>
                        <ul>
                            <li>
                                <Link to="/policy">
                                    {i18n('privacyPolicy')}
                                </Link>
                            </li>
                            <li>
                                <Link to="/terms-in-use">
                                    {i18n('refundConditions')}
                                </Link>
                            </li>
                            <li>
                                <Link to="/faq">{i18n('questionAnswer')}</Link>
                            </li>
                        </ul>
                    </div>
                    <div className={style.footer__inner__social}>
                        <h1>{i18n('social')}</h1>
                        <div className={style.footer__inner__social__logos}>
                            <a
                                href="https://t.me/TwentyOnePool"
                                target="_blank"
                            >
                                <img
                                    src={require('@/shared/images/svg/social/green-telegram.png')}
                                    alt=""
                                />
                            </a>
                            <a
                                href="https://twitter.com/21poolio"
                                target="_blank"
                            >
                                <img
                                    src={require('@/shared/images/svg/social/green-twitter.png')}
                                    alt=""
                                />
                            </a>
                            {/*<a*/}
                            {/*    href="https://twitter.com/21poolio"*/}
                            {/*    target="_blank"*/}
                            {/*>*/}
                            {/*    <img*/}
                            {/*        src={require('@/shared/images/svg/social/green-youtube.png')}*/}
                            {/*        alt=""*/}
                            {/*    />*/}
                            {/*</a>*/}
                            {/*<a*/}
                            {/*    href="https://twitter.com/21poolio"*/}
                            {/*    target="_blank"*/}
                            {/*>*/}
                            {/*    <img*/}
                            {/*        src={require('@/shared/images/svg/social/green-linkedin.png')}*/}
                            {/*        alt=""*/}
                            {/*    />*/}
                            {/*</a>*/}
                            <a
                                href="https://www.instagram.com/21pool.io?igsh=MTJ6bHV6cjkzM3l3dg%3D%3D&utm_source=qr"
                                target="_blank"
                            >
                                <img
                                    src={require('@/shared/images/svg/social/green-instagram.png')}
                                    alt=""
                                />
                            </a>
                            <a href="mailto:info@21pool.io" target="_blank">
                                <img
                                    src={require('@/shared/images/svg/social/green-email.png')}
                                    alt=""
                                />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}
