import { createContext, ReactNode } from 'react'
import { IGlobalContext, useGlobalParams } from '@/entities'
export * from './dialog-context'
export const GlobalContext = createContext<IGlobalContext>({
    lang: 'ru',
    currency: 'BTC',
    isLogged: false,
    theme: 'normal',
    setCurrency: () => {},
    changeTheme: () => {},
    changeLanguage: () => {},
    setCurrentUser: (user) => {},
    currentUser: null,
    setIsLogged: () => {},
    openMenu: [false, () => {}],
    sidebarState: [false, () => {}],
})

export function GlobalProvider({ children }: { children: ReactNode }) {
    const props = useGlobalParams()
    return (
        <GlobalContext.Provider value={{ ...props }}>
            {children}
        </GlobalContext.Provider>
    )
}
