import { configureStore } from '@reduxjs/toolkit'
import { referenceReducer } from '@/store/references'
import { apiKeyReducer } from '@/store/api_key'
import { subAccountReducer } from '@/store/sub_accounts'
import { useDispatch } from 'react-redux'
import { globalReducer } from '@/store/global'
import { referalReducer } from '@/store/referal'

export const store = configureStore({
    reducer: {
        apiKey: apiKeyReducer,
        subAccount: subAccountReducer,
        references: referenceReducer,
        referal: referalReducer,
        global: globalReducer,
    },
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
export const useAppDispatch = () => useDispatch<typeof store.dispatch>()
